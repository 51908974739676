declare global {
    interface Window {
        o365_i18n?: {
            [key: string]: string
        },
        o365_framework_i18n?: {
            [key: string]: string
        },
        $t: (pValue: string) => string;
    }
}

const filterCiteriasTemp =  {
    "isnull":"Is null",
    "isnotnull":"Is not null",
    "beginswith": "Starts with",
    "endswith": "Ends with",
    "greaterthan": "Greater than",
    "equals": "Equals",
    "lessthan": "Less than",
    "contains": "Contains",
    "datebetween": "Date between",
    "dateequals": "Date equals",
    "inlist": "In list",
    "datelessthanorequal":"Date less than or equals",
    "dategreaterthanorequal":"Date greater than or equals"
}

let o365_Translations = self?.o365_i18n ?? {};
const o365_FrameworkTranslations = self?.o365_framework_i18n ?? {};

if (window.new_i18n) {
    o365_Translations = Object.entries(o365_Translations).map(s => s[1]).reduce((acc, cur) => {return {...acc, ...cur}});
}

const translations = window.new_i18n ? <{[key: string]: string}>{
    ...filterCiteriasTemp,
    ...o365_Translations
} : <{[key: string]: string}>{
    ...filterCiteriasTemp,
    ...o365_Translations,
    ...o365_FrameworkTranslations
};

const translate = (pValue: string, source?: string): string => {
    if (!window.new_i18n) {
        return fallbackTranslate(pValue);
    }

    if (source) {
        return newTranslate(pValue, source);
    }
        
    return newTranslate(pValue, 'site');
};

const fallbackTranslate = (pValue: string): string => {
    if (translations.hasOwnProperty(pValue)) {
        return translations[pValue];
    }

    return pValue;
};

const newTranslate = (pValue: string, source: string): string => {
    return self?.o365_i18n?.[source]?.[pValue] ?? fallbackTranslate(pValue);
};

// Make the translate function available in global scope. Required for NT/CT NavBar functionality.
window.$t = translate;

export default translate;